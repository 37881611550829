import React, { useEffect } from 'react';

const Practice = () => {
  

  return (
    <div className="App">
      <h1>Welcome to My App</h1>
     
    </div>
  );
}

export default Practice;
